import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import * as styles from '../styles/pages/product.module.css'

import image_k201 from '../images/product/menu/k201.jpg'
import image_k201m from '../images/product/menu/k201_mobile.jpg'
import image_k202 from '../images/product/menu/k202.jpg'
import image_k202m from '../images/product/menu/k202_mobile.jpg'
import image_k210 from '../images/product/menu/k210.jpg'
import image_k210m from '../images/product/menu/k210_mobile.jpg'
import image_k222 from '../images/product/menu/k222.jpg'
import image_k222m from '../images/product/menu/k222_mobile.jpg'
import image_k231 from '../images/product/menu/k231.jpg'
import image_k231m from '../images/product/menu/k231_mobile.jpg'
import image_k630 from '../images/product/menu/k630.jpg'
import image_k630m from '../images/product/menu/k630_mobile.jpg'


const ProductPage = () => (
  <Layout mobileTitle={true} activePath='product' pageTitle="For Walls and Ceilings">
  	<Link to="/product/wall/k201" className={styles.productcolumn}>
  		<img className={styles.productitem+" fullblock-desktop-only"} src={image_k201} alt="K-201" />
      <img className={styles.productitem+" fullblock-mobile-only"} src={image_k201m} alt="K-201" />
  	</Link>
  	<Link to="/product/wall/k202" className={styles.productcolumn}>
  		<img className={styles.productitem+" fullblock-desktop-only"} src={image_k202} alt="K-202" />
      <img className={styles.productitem+" fullblock-mobile-only"} src={image_k202m} alt="K-202" />
  	</Link>
  	<Link to="/product/wall/k210" className={styles.productbanner}>
  		<img className={styles.productitem+" fullblock-desktop-only"} src={image_k210} alt="K-210" />
  		<img className={styles.productitem+" fullblock-mobile-only"} src={image_k210m} alt="K-210" />
  	</Link>
  	<Link to="/product/wall/k222" className={styles.productcolumn}>
  		<img className={styles.productitem+" fullblock-desktop-only"} src={image_k222} alt="K-222" />
      <img className={styles.productitem+" fullblock-mobile-only"} src={image_k222m} alt="K-222" />
  	</Link>
  	<Link to="/product/wall/k231" className={styles.productcolumn}>
  		<img className={styles.productitem+" fullblock-desktop-only"} src={image_k231} alt="K-231" />
      <img className={styles.productitem+" fullblock-mobile-only"} src={image_k231m} alt="K-231" />
  	</Link>
  	<Link to="/product/wall/k630" className={styles.productbanner}>
  		<img className={styles.productitem+" fullblock-desktop-only"} src={image_k630} alt="K-630" />
  		<img className={styles.productitem+" fullblock-mobile-only"} src={image_k630m} alt="K-630" />
  	</Link>
  </Layout>
)

export default ProductPage
